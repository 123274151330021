import React from 'react';
import InnerLayout from "../../../hoc/InnerLayout/InnerLayout";
import Navigation from "../../../components/Navigation/Navigation";
import Layout from "../../../hoc/Layout/Layout";
import YearsIncomeComponent from "../../../components/ContentRow/Step1/YearsIncomeComponent/YearsIncomeComponent";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { retirementCalcLinks } from "../../../Routes/RoutesConst";

const YearsIncome = (props) => {

    const titles = {
        layoutTitle: 'TELL US ABOUT YOURSELF',
        sectionTitle: 'Based on your retirement age you may require:',
    };

    return (
        <Layout
            title={titles.layoutTitle}
            progressStep1={(3/7)*100}
            active={'step1'}
        >
            <Helmet>
                <title>Based on your age how much will you need in retirement?</title>
            </Helmet>

            <InnerLayout
                sectionTitle={titles.sectionTitle}
                modalContent='yearsIncome'
            >

                <YearsIncomeComponent/>

            </InnerLayout>

             <Navigation
                 isCompleted={(props.yearsIncome > 0 && props.averageLife <= 100) ? true : false}
                 next={retirementCalcLinks.rentOrOwn}
             />

        </Layout>
    )
};

function mapStateToProps(state) {
    return {
        yearsIncome: state.aboutYou.yearsIncome,
        averageLife: state.aboutYou.averageLife,
    }
}

export default connect(mapStateToProps, null)(YearsIncome);