import React, { useState } from 'react';
import classes from './YearsIncomeComponent.module.scss'
import { setYearsIncome } from "../../../../store/actions/aboutYou";
import { connect } from "react-redux";
import Error from "../../../Error/Error";

const YearsIncomeComponent = ( props ) => {
    const [years, setYears] = useState({
                        yearsIncome: props.averageLife - props.retireAge,
                        averageLife: props.averageLife
                                       });
    const initErrorState ={
        isError: (years.yearsIncome <= 0 || props.averageLife>100) ? true : false,
        errorMessage: years.yearsIncome <= 0 ? 'Selection required' : `Value can not be greater than ${years.yearsIncome}`
    };

    const [error, setError] = useState(initErrorState);

    const validateYearsOfIncome = (yearsVal, averageLife) => {
        let isError = false;
        let errorMessage = 'Selection required';

        if(yearsVal <=0 ) {
            isError = true
        }

        if(averageLife > 100 ) {
            isError = true;
            errorMessage = `Value can not be greater than ${100 - props.retireAge}`
        }

        setError({
                 ...error,
                 isError: isError,
                 errorMessage: errorMessage
             });

        setYears({
                     ...years,
                     yearsIncome: yearsVal,
                     averageLife: averageLife
                 });

        props.onChange(yearsVal, averageLife)
    };

    const onChangeYearsIncome = (e) => {
        const yearsVal = e.target.value;
        const averageLife = props.retireAge + parseInt(yearsVal);

        validateYearsOfIncome(yearsVal, averageLife)

    };

    const onChangeAverageLife = (e) => {
        const averageLife = e.target.value;
        const yearsVal =  parseInt(averageLife) - props.retireAge;

        validateYearsOfIncome(yearsVal, averageLife)

    };

    return (
        <div className={classes.YearsIncomeWrap}>
            <div className={classes.YearsIncome}>
                {
                    error.isError &&
                    <Error message={error.errorMessage}/>
                }
                <strong style={{display: 'block'}}>Years of income</strong>
                <input
                    type = "number"
                    name="yearsIncome"
                    value={years.yearsIncome}
                    onChange={onChangeYearsIncome}
                    style={{marginBottom: 5, textAlign: "center"}}
                />
            </div>
            <div className={classes.AverageLife}>
                <strong>That's age {props.retireAge} to</strong>
                <input
                    type = "number"
                    name="averageLife"
                    value={years.averageLife}
                    onChange={onChangeAverageLife}
                    style={{textAlign: "center"}}
                />
                <p>Continue with average life expectancy or edit age</p>
            </div>
        </div>
    )
};

function mapStateToProps(state) {
    return {
        retireAge: state.aboutYou.retireAge,
        averageLife: state.aboutYou.averageLife,
        yearsIncome: state.aboutYou.yearsIncome,
    }
}


function mapDispatchToProps(dispatch) {
    return {
        onChange: (yearsVal, averageLife) => dispatch(setYearsIncome(yearsVal, averageLife)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(YearsIncomeComponent);